let isDirty = false

const setIsDirty = _ => isDirty = true
window.clearIsDirty = _ => isDirty = false

window.addEventListener("change", setIsDirty)

const submitButtons = document.querySelectorAll('button[type="submit"]')

submitButtons.forEach(button => {
    button.addEventListener('click', clearIsDirty)
})

window.onbeforeunload = _ => {
    if (isDirty && !document.querySelector('html').classList.contains('iframe-mode')) {
        return '';
    }
}
