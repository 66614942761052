let resizeTimout

// call dialog only with dialog name, without '#dialog-'
window.openDialog = (dialog, event) => {
    window.closeDialog()

    const dialogEle = document.querySelector(`#dialog-${dialog}`)
    if(dialogEle) {
        dialogEle.classList.add('is-active')
        document.querySelector('html').classList.add('prevent-scroll')
    }

    if (dialog === 'mobile-menu') {
        window.addEventListener('resize', _ => {
            clearTimeout(resizeTimout)
            resizeTimout = setTimeout(_ => {
                if (window.innerWidth >= 1023) {
                    window.closeDialog()
                }
            }, 300)
        })
    }

    const modalBackgrounds = document.querySelectorAll('.modal-background')

    modalBackgrounds.forEach(background => {
        background.addEventListener('click', window.closeDialog)
    })
}

window.closeDialog = _ => {
    const dialogEle = document.querySelector('.modal.is-active')
    if(dialogEle) {
        dialogEle.classList.remove('is-active')
    }
    document.querySelector('html').classList.remove('prevent-scroll')
}

window.addEventListener('keydown', (e) => {
    return !e.repeat && ['Escape', 'Esc'].includes(e.key) && window.closeDialog()
});
