import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


const CancelToken    = axios.CancelToken;
const timeoutMap     = {};
const cancelTokenMap = {};


function debouncedFetch(url, body, id = uuidv4(), timeout = 250) {
    // Cancel scheduled requests
    clearTimeout(timeoutMap[id]);

    // Cancel still going requests
    cancelTokenMap[id]?.cancel();

    // Schedule a new request
    return new Promise((resolve, reject) => {
        timeoutMap[id] = setTimeout(() => {
            // Generate new cancel token
            cancelTokenMap[id] = CancelToken.source();

            // Fire request
            axios.post(url, body, {
                headers: { 'content-type': 'application/json' },
                cancelToken: cancelTokenMap[id].token,
            })
                .then(resolve)
                .catch(error => {
                    // Only bubble up errors that are no cancellations
                    if (!axios.isCancel(error)) {
                       reject(error.response);
                    }
                })
                .finally(() => {
                    // Clean up afterwards
                    delete timeoutMap[id];
                    delete cancelTokenMap[id];
                })

        }, timeout);
    });
}

window.debouncedFetch = debouncedFetch;
