/**
 * Autoloader for `_loder.js` files.
 *
 * This code is taked straight from the Webpack documentation: https://webpack.js.org/guides/dependency-management/#context-module-api
 * It adds the functionality to automatically load certain files. This makes adding new modules easier and helps us
 * split our code into chunks.
 *
 * This was added so that all the code belonging to a module can really be in one place, even the code that decides
 * whether to load the module or not. And with the chunk splitting this gives us, hot reload times are much, much faster.
 *
 */

// Some cache for Webpack to load things faster. I don't know I just copied the code.
const cache = {};

// Magic function that can actually load modules given to it.
function importAll(r) {
  r.keys().forEach(key => (cache[key] = r(key)));
}

//
// Autoload files
// Load all `_loader.js` files in the `static/app/modules` directory.
importAll(require.context('../../templates', true, /.*\/_loader\.js$/));
