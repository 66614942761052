import { popperGenerator, defaultModifiers } from '@popperjs/core/lib/popper-lite';
import { arrow, offset, preventOverflow, flip } from '@popperjs/core/lib/modifiers';

const createPopper = popperGenerator({
    defaultModifiers: [...defaultModifiers, flip, preventOverflow, offset, arrow],
});

const popperInstances = []

const tooltipIcons = document.querySelectorAll('.tooltip-trigger')

function show(event) {
    const { tooltip: tooltipId } = event.target.dataset
    const tooltip = document.querySelector(`.tooltip-${tooltipId}`)
    tooltip.setAttribute('data-show', '');

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    popperInstances[`tooltip-${tooltipId}`].update();
}

function hide(event) {
    const tooltip = document.querySelector(`.tooltip-${event.target.dataset.tooltip}`)
    tooltip.removeAttribute('data-show');
}

const showEvents = ['mouseenter', 'focus'];
const hideEvents = ['mouseleave', 'blur'];

const addEvents = tooltipIcon => {
    showEvents.forEach(event => {
        tooltipIcon.addEventListener(event, show);
    });

    hideEvents.forEach(event => {
        tooltipIcon.addEventListener(event, hide);
    });
}

window.addNewTooltip = tooltipIcon => {
    const { tooltip: tooltipId, tooltipPlacement } = tooltipIcon.dataset

    popperInstances[`tooltip-${tooltipId}`] = createPopper(tooltipIcon, document.querySelector(`.tooltip-${tooltipId}`), {
        placement: tooltipPlacement || 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 25],
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    padding: 20
                }
            },
        ],
    });

    addEvents(tooltipIcon)
}

tooltipIcons.forEach(tooltipIcon => {
    addNewTooltip(tooltipIcon)
})
