window.formatDate = (date, cb) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return cb({ year, month, day })
}

// format iban input
window.formatIban = ({target}) => {
    let { selectionEnd: position, value: { length: length }} = target
    target.value = target.value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').toUpperCase().trim();
    target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);
}

document.querySelector('button.form-backward-button')?.addEventListener('click', event => {
    event.preventDefault()
    const form = event.target.closest('form')
    if (form) {
        form.setAttribute('novalidate', true)

        const input = document.createElement('input')
        input.setAttribute('name', event.target.closest('button').name)
        input.setAttribute('type', 'hidden')
        input.setAttribute('value', true)

        form.appendChild(input)

        form.submit()
    }
})

document.querySelector("form[name=order] .form-forward-button")?.addEventListener('click', event => {
    const downloadsModule = document.querySelector('.downloads')
    if (downloadsModule) {
        event.preventDefault()
        const form = event.target.closest('form')

        const errorBox = document.querySelector('.download-box .error-box')
        const downloadItems = downloadsModule.querySelectorAll('.downloads__item input[type=checkbox]')
        const checkedDownloadItems = [...downloadItems].filter(item => item.checked)

        if (checkedDownloadItems.length === downloadItems.length) {
            if(errorBox) {
                errorBox.style.display = 'none'
            }
            form.submit()
        } else {
            if(errorBox) {
                errorBox.style.display = 'block'
            }
        }
    }

})
